
<!--定期更换审批-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">{{ $t('regular_replacement_approval') }}</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="" icon="el-icon-back" @click="back">{{ $t('return') }}</el-button>
        </div>
      </div>
      <div class="form-box" style="max-width: 1000px">
        <el-form
            ref="formList"
            :model="formList"
            label-width="120px"
            :rules="rules"
            label-position="left"
        >
          <el-form-item :label="$t('deviceName')" prop="name">
            <div>{{ formList.name }}</div>
          </el-form-item>
          <el-form-item :label="$t('replacement_item')" prop="" style="max-width: 1000px">
            <div>{{ formList.project }}</div>
          </el-form-item>
          <el-form-item :label="$t('remark')" prop="code">
            <div>{{ formList.remark }}</div>
          </el-form-item>
          <el-form-item :label="$t('submitter')" prop="name">
            <div>{{ formList.person}}</div>
          </el-form-item>
          <el-form-item :label="$t('submit_time')" prop="name">
            <div>{{ formList.date }}</div>
          </el-form-item>
          <el-form-item>
            <el-button plain  type="primary" class="submit-btn" @click="handleSubmit">{{ $t('rejected') }}</el-button>
            <el-button  @click="handleSwitch" class="submit-btn" type="primary" >
              {{ $t('approved') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api'
export default {
  name: "Approval",
  data() {
    return {
      flag: null,
      hide: false,
      minute: 0,
      second: 0,
      formList: {},
      infoList: [],
      list: [],
      data: {
        imageUrls: []
      },
      remark: "",
      rules: {
        lubricationScore: [
          { required: true, message: "请输入润滑得分", trigger: "blur" },
          {
            pattern: /[0-9]\d*/,
            max: 60,
            message: this.$t('positive_integer'),
            trigger: "change",
          },
        ],
        cleanScore: [
          { required: true, message: "请输入检查、清洁得分", trigger: "blur" },
          {
            pattern: /[1-9]\d*/,
            max: 20,
            message: this.$t('positive_integer'),
            trigger: "change",
          },
        ],
        adjustScore: [
          { required: true, message: "请输入调节得分", trigger: "blur" },
          {
            pattern: /[1-9]\d*/,
            max: 20,
            message: this.$t('positive_integer'),
            trigger: "change",
          },
        ],
      },
    }
  },
  created() {
    this.updateDevice()
  },
  mounted () {
  },
  methods: {
    back() {
      this.$router.push(`PrintHome`)
    },
    updateDevice() {
      API.approvalListInfo(this.$route.query.id,{type: 4,page: 1,count:100}).then(res => {
        console.log(res)
        this.formList = res.message.data
      })
    },
    handleSubmit () {
      API.approvalList({regularId:this.$route.query.id,state:2}).then(res => {
        if (res.message.success === true) {
          this.$message({message: this.$t('operation_successful'),type: 'success',});
          this.$router.back();
        } else {
          this.$message({message: '操作失败',type: 'error',});
        }
      })
    },
    handleSwitch() {
      API.approvalList({regularId:this.$route.query.id,state:1}).then(res => {
        if (res.message.success === true) {
          this.$message({message: this.$t('operation_successful'),type: 'success',});
          this.$router.back();
        } else {
          this.$message({message: '操作失败',type: 'error',});
        }
      })

    },
  }
}
</script>
<style lang="scss" scoped>
.widthTable {
  width: 1550px;
}
.title {
  font-size: 18px;
  color: #3D3D3D;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.title div:last-child{
  font-size: 16px;
  font-weight: 400;
}
.el-table .hidden-row {
  display: none;
}
.bofang {
  cursor: pointer;
}
.group {
  .item_img {
    //height: 100px;
    overflow: hidden;
    //line-height: 100px;
    //margin-bottom: 10px;
    text-align: center;
  }
}
</style>
